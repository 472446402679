<template>
  <div id="app">
    <img class="banner" :src="this.url.imgUrl + bannerImg" alt="立优教育" />
    <!-- <img class="banner" src="../../assets/img/logo.png" alt="立优教育" /> -->
    <div class="down">
      <div class="login_card">
        <!-- <el-input class="password" type="text" placeholder="请输入密码" /> -->
        <!-- 注册卡片 -->
        <div class="login_box">
          <div class="login">
            <router-link :to="'/'">
            <img :src="this.url.imgUrl + logoImg" alt="立优教育" />
            </router-link>
            <div id="login_line"></div>
            <div class="login_right">
              <div style="margin-bottom: 29px">注册账号</div>
              <div class="register_ipt">
                <input
                  type="text"
                  placeholder="请输入手机号码"
                  v-model="form.mobile"
                  maxlength="11"
                />
                <img src="../../assets/img/phone.png" alt="立优教育" />
              </div>

              <!-- 图形验证码 -->
              <div class="register_ipt">
                <div class="img_code_box">
                  <input
                    type="text"
                    placeholder="图形验证码"
                    maxlength="4"
                    v-model="form.verification_code"
                  />
                  <!-- <el-input placeholder="图形验证码" maxlength="4" v-model="verification_code">
                                        <i slot="prefix" class=""></i>
                                    </el-input> -->
                  <img src="../../assets/img/pic.png" alt="立优教育" />
                  <!-- @click="changeImg" -->
                  <div class="code_img" style="left: 301px">
                    <img
                      style="width: 130px; height: 38px"
                      id="redom"
                      :src="captchaImg"
                      alt="立优教育"
                      @click="getImgCode()"
                    />
                  </div>
                </div>
              </div>
              <div class="register_ipt get_code">
                <input
                  placeholder="获取短信验证码"
                  maxlength="4"
                  v-model="form.code"
                />
                <!-- <el-input placeholder="获取短信验证码" maxlength="4" v-model="code">
                                    <i slot="prefix" class=""></i>
                                </el-input> -->
                <img src="../../assets/img/suo.png" alt="立优教育" />
                <button
                  class="send_code"
                  :disabled="disabled"
                  @click="sendCode"
                >
                  {{ btntxt }}
                </button>
              </div>
              <div class="register_ipt">
                <input
                  type="password"
                  placeholder="请输入您的密码"
                  v-model="form.pwd"
                />
                <img src="../../assets/img/password.png" alt="立优教育" />
              </div>
              <div class="register_ipt" id="select_option">
                <el-select
                  v-model="form.value"
                  placeholder="请选择您的备考科目"
                  style="width: 388px; height: 50px"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.subject_id"
                    :label="item.name"
                    :value="item.subject_id"
                  >
                  </el-option>
                </el-select>
                <img
                  style="width: 22px; height: 19px"
                  src="../../assets/img/kemu.png"
                  alt="立优教育"
                />
              </div>
              <ul class="treaty_box">
                <li>
                  注册表示同意<span @click="showMask">《网站注册协议》</span>
                </li>
                <li>
                  已有账号？<router-link to="/login">立即登录</router-link>
                </li>
              </ul>
              <button class="register_btn" type="button" @click="registerNow">
                立即注册
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="record_number">
        备案号：湘ICP备 2021000943号 <br />
        联系地址：长沙高新开发区麓谷大道658号湖南麓谷信息港15002-3 <br />
        联系电话：400-656-6816 服务时间：周一至周五9:00-12:00 14:00-17:00<br />
      </div>
      <!-- 注册协议 -->
      <!--  -->
      <div v-if="showDailog == true">
        <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="55%"
          :before-close="handleClose"
        >
          <div id="mask_title">用户协议</div>
          <span id="mask">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;《用户注册协议》（以下简称“本协议”）是由您与长沙立优教育科技有限公司（以下简称“立优教育”）以数据电文形式共同缔结的协议，请您务必审慎阅读、充分理解各条款内容，限制、免责条款可能以黑体加粗形式提示您注意。当您在线点击/勾选“同意”或“接受”键后，视为您已详细阅读并同意遵守本协议，本协议生效，则立优教育根据本协议为您提供网上服务。因此，除非您已阅读并接受本协议所有条款，否则您无权使用立优教育网上服务。
            若您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
            立优教育有权在必要时更新协议内容并以通知、公告、声明或其它形式通知您。若您不同意修改内容，应立即停止使用相关服务；否则，您的任何对相关服务的登录、查看、使用等行为将被视为您对修改内容的理解和接受。
            <h3>1.定义</h3>
            如无特别说明，下列术语在本协议中的含义为：
            1.1.您：指注册成功并使用立优教育网上服务的完全民事行为能力人，又称“学员”或“用户”。
            1.2.立优教育：是“长沙立优教育科技有限公司”在本协议中的简称。
            1.3.网上服务：指由立优教育运营的、通过计算机客户端、网页端、移动端等端口向您提供的各项网上服务的统称，服务内容包括但不限于考试资讯，资料中心，模考系统，公开课，付费课。
            1.4.立优教育网络培训平台：是指立优教育借以向您提供各项网上服务的综合服务系统。
            1.5.立优教育组成元素：指立优教育各端口页面所包含的及与立优教育相关的软件、文字、图片、音频、视频、图标、标识、界面设计、版面框架、编排方式、有关数据或电子文档等内容和信息。
            1.6.本协议：指《用户注册协议》正文及其修订版本。上述内容一经正式发布，即为本协议不可分割的组成部分。
            <h3>2.注册账号</h3>
            2.1.您将在完成注册程序后获得立优教育网上服务的账号。您享有该账号的使用权。立优教育享有该账号的所有权。立优教育有权对账号的权限设计进行调整。
            2.2.立优教育有理由认为该账号下的所有行为都是您的真实意思表示。您享有该账号下行为产生的所有权利，同时应承担该账号下所有行为及事件产生的义务及责任。若您账号下的行为对您、立优教育或第三方造成的损害，您将负全部责任。
            若该账号通过立优教育网上服务展示任何违法、不道德或立优教育认为不合适的资料；或者立优教育有理由怀疑该资料属于程序或恶意操作，立优教育有权暂停或终止您的帐号，并拒绝您于现在和未来使用本服务之全部或任何部分。
            2.3.账号的取得
            2.3.1.您应当按照立优教育网络培训平台注册页面的要求完成注册程序，并保证填写的个人信息与您的身份信息一致，否则立优教育有权拒绝向您提供相关服务或承担任何义务。
            2.3.2.除非您在个人信息中填写真实身份信息，否则您无权购买立优教育提供的网上付费服务（例如购买学习卡、图书或课程等）。
            2.3.3.立优教育有权核实您注册账号时所提供的身份信息是否真实、有效。若您违反注册要求，未使用您本人的真实身份信息进行注册，立优教育有权随时关闭您的账号。
            2.3.4.立优教育有权采取技术与管理等合理措施保障您账号的安全、有效。
            2.4.授权协助注册
            若您在注册过程中主动向立优教育或其工作人员寻求支持和帮助，则您提出要求及指示的行为即视为您对立优教育协助您注册账号的委托授权，因此产生的后果及责任由您承担。
            2.5.注册信息的修改
            若您在注册成功并获得立优教育网络培训平台的账号后，需要修改注册信息，请按照立优教育出于安全考虑设置的相关修改程序操作。若立优教育有合理理由怀疑您提交的相关资料或信息属于恶意程序或恶意操作，则立优教育有权关闭您的账号，并有权拒绝您于现在和将来使用立优教育所提供服务之全部或任何部分。
            2.6.账号的使用和保管
            2.6.1.您有义务妥善保管并安全使用您的账号及密码并承担相关责任。若您未保管好自己的帐号和密码，因此而产生的任何损失或损害，立优教育无法也不承担任何责任。
            2.6.2.若您发现您的账号使用出现异常（被他人盗用等任何安全问题），应立即通知立优教育并提供您的个人有效身份信息以供核实账户身份。经核实身份一致的，立优教育应当及时采取措施暂停该账号的登录和使用；身份不一致或者您拒绝提供个人有效身份证明的，立优教育有权拒绝您提出的关于该账号的管理请求。
            2.6.3.您在立优教育注册的账号，仅限您个人使用，不得将账号与第三人共享或将账号转让、赠与他人等。否则，立优教育有权关闭您的账号。
            2.7.若立优教育发现您的帐号存在异常状况(包括但不限于异地登陆、IP地址异常变动、发送注册信息变更请求等)，立优教育则有权依据其合理判断采取相应措施(包括但不限于要求用户进行手机实名认证、进行面部识别等)以保护帐号安全。
            <h3>3.服务内容</h3>
            3.1.基于自身的经营自主权，立优教育有权自主决定通过互联网提供的服务（产品）类型及内容。
            立优教育网上服务目前包含以下内容：
            3.1.1.基础服务：即立优教育网络培训平台上提供的非付费的网上服务，包括：免费考试相关网上咨询；免费发布考试资讯，免费提供部分考试资料，免费提供部分考试的在线模考系统，免费提供部分直播视频公开课，免费提供部分视频公开课等非付费网上服务。
            3.1.2.增值服务：即付费服务，是立优教育为有需要的用户提供的、用户可以依据相关产品协议在网络培训平台上购买并享受的服务（产品）。例如，立优教育网络培训平台的网上付费课程。
            3.1.3.其他服务：立优教育通过网络培训平台为您提供的其他服务。
            3.2.立优教育网上服务的具体内容以立优教育在您使用时实际提供给您的情况和版本为准。
            3.3.立优教育在此许可您依本协议而获得立优教育网上服务之基础服务的使用权。
            3.4.除非另有其它明示规定，立优教育网络培训平台内所增加或强化的任何新功能，包括所推出的新产品，均受到本使用协议之规范。
            3.5.立优教育提供的资讯信息类服务，例如与培训课程有关的报名、考试信息等资讯服务，其内容均应以有权主管部门公布的信息为准。立优教育及其代表不提供对报名、考试信息的任何形式的承诺或保证，也不存在任何形式的保过、密题、内幕信息或类似承诺。用户应独立收集信息并进行判断，并承担相关后果。如因与相关培训课程有关的报名、考试信息发生变动而造成用户学习成本上升、丧失考试资格等损失，立优教育及立优教育网络培训平台不负有任何责任。
            3.6.立优教育有随时调整各项服务费用收取标准的权利，但已完成支付的订单不受影响；您有权自主决定是否购买立优教育提供的网上增值服务（产品）。
            3.7.针对增值服务(付费类课程产品和服务)的特别约定
            用户可根据自身实际需求选择免费服务（产品）或有偿、付费服务（产品）。针对有偿课程服务（产品），用户请注意下述特别事项：
            3.7.1.您可通过立优教育的计算机客户端、网页端、移动端等端口购买立优教育提供的有偿服务（产品）。根据您所订购产品的具体信息(以订购当时立优教育官网公示为准)，包括但不限于课程内容、课程安排、价格、退款政策等，在您支付完成后，将由立优教育系统后台生成1份电子订单，该电子订单系本协议之有效组成部分，是确定用户与立优教育各自权利义务的有效依据。支付完成后您即可根据所购买课程享受立优教育提供的各项服务。立优教育针对部分课程，可能会需要向您邮寄提供教材、讲义等课程资料，因此请您在提交订单前务必仔细核对收货人、收货地址及联系电话等信息。收货人与用户本人不一致的，收货人的行为和意思表示即视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。
            3.7.2.立优教育针对不同的课程产品分别拟定了对应的《培训服务协议》，其中规定了双方在此课程服务提供及使用过程中的权利义务，您应在购买相关课程产品前，仔细阅读并保证已充分了解该《培训服务协议》的全部内容。您提交订购请求的行为即视为您已经完全了解并同意该《培训服务协议》。
            3.7.3.立优教育的任何一个帐号都只能由同一个用户使用，禁止多个用户使用同一个帐号。若立优教育有合理理由认为您的账号可能存在用户帐号被盗取、被盗用情形的，则立优教育有权将此帐号暂时或永久作无效处理并保留追究法律责任的权利。若您将帐号借给他人使用，您应承担由此产生的全部责任，并与实际使用人承担连带责任。
            3.7.4.您应在享受课程服务过程中自觉维护课堂秩序，不得作出、参与任何影响课堂正常秩序的行为。否则，立优教育保留追究您责任的权利。情节严重者，立优教育有权关闭其账号且不承担任何责任。
            <h3>4.双方权利义务</h3>
            4.1.立优教育仅负责通过网络培训平台向您提供网上服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由您自行负担。
            4.2.您在立优教育网络培训平台享有的部分网上服务（包括但不限于付费课程）可能受到有效期的限制，一旦有效期届满，该服务将会自动失效。请您在付费前仔细阅读相关条款。
            4.3.您可以选择购买立优教育提供的增值（付费）服务。您应当在付费前仔细阅读并同意关于该付费服务的相关条款，包括但不限于按照立优教育的要求进行实名认证并同意将所填写的信息交由第三方机构进行校验。您应保证您所提供的资料和信息是真实、完整、有效的。
            4.4.若您选择通过第三方支付平台等方式进行费用支付，在支付过程中因您自身原因或第三方支付平台原因造成的异常使您的帐号无法正常使用或遭受损失的，立优教育对此概不负责。
            4.5.立优教育对于其通过网络培训平台所提供的网上服务的种类及内容拥有自主权。立优教育有权随时更改经营模式、管理应用程序及内部功能。
            4.5.1.由于考试大纲变化、师资变动等原因，立优教育有权调整培训内容、授课老师。
            4.5.2.立优教育有权根据实际情况的变化调整课程设置，也有权调整服务提供流程
            (包括但不限于退课、换课、赠课流程等)。
            4.6.立优教育可能会通过应用官方网站、客服电话、管理员、或者其他的途径，向您提供诸如课程说明、课程锁定或解除锁定、帐号申诉等客户服务，以上服务的提供应建立在以下前提条件之上：
            (1)您应通过立优教育客服官方网站或者提供的其他途径了解这些客户服务的内容、要求以及资费，谨慎选择是否需要享受相应的客户服务，向立优教育真实地准确地表达您的需求；
            (2)您应同意并接受立优教育关于该等客户服务的专门协议或条款。
            4.7.为高效利用服务器资源，若您长期未使用账号登录立优教育网站，立优教育有权视需要，在提前通知的情况下，对该账号及其账号下的数据及相关信息采取删除等处置措施，上述处置可能导致您对该账号下相关权益的丧失，对此立优教育不承担任何责任。
            4.8.您充分理解并同意接受立优教育通过短信或其他方式向您发送立优教育的相关商业信息。如您希望停止接收相关信息，可通过立优教育官方网站公布的联系方式与立优教育联系，立优教育将在核实您的身份后取消推送。
            <h3>5.用户行为规范</h3>
            5.1.您除了可以按照本协议的约定使用立优教育网上服务之外，不得进行任何侵犯立优教育及组成元素相关的知识产权的行为，也不得进行任何其他有损于立优教育或其他第三方合法权益的行为。
            5.2.您仅可为非商业目的使用立优教育网上服务，包括但不限于接收、下载、安装、启动、升级、登录、显示、运行和/或截屏立优教育网站服务。
            5.3.您不得以任何形式公开立优教育提供的网上课程（包括但不限于向任何第三方透露学习课程、与他人共享账号、将自己的账号提供给第三方使用、将学习课程公开播放或以任何方式供多人同时使用）。上述情况一经发现，立优教育有权立即关闭相应账号的学习权限，同时立优教育会进一步追究您违规使用账号引起的法律责任。
            5.4.在使用立优教育网上服务的过程中，您必须遵守相关服务的具体使用规则，同时遵守以下基本行为规范，否则，立优教育有权立即关闭相应账号的学习权限，并进一步追究您行为的法律责任。
            (1)不得为任何非法目的而使用网络服务系统；
            (2)遵守所有与网络服务有关的网络协议、规定和程序；
            (3)不得利用立优教育网络培训平台进行任何可能对互联网的正常运转造成不利影响的行为；
            (4)不得利用立优教育网络培训平台进行任何对该平台不利的行为。
            5.5.您应当保证对您所发表或者上传于立优教育网络培训平台的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；若您违反本条规定造成本公司被第三人索赔的，您应全额补偿本公司一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；当第三方认为您发表或者上传于立优教育网络培训平台的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本公司发送权利通知书时，您同意本公司可以自行判断决定删除涉嫌侵权信息，除非您提交书面证据材料排除侵权的可能性，本公司将不会自动恢复上述删除的信息。
            5.6.若因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；立优教育因此遭受损失的，您也应当一并赔偿。
            5.7.您必须严格遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号)》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。
            5.8.您不得在使用立优教育网上服务的过程中在立优教育网络培训平台的任何页面发布、转载、传送含有下列内容之一的信息：
            （1）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            （2）损害国家荣誉和利益的；
            （3）煽动民族仇恨、民族歧视、破坏民族团结的；
            （4）破坏国家宗教政策，宣扬邪教和封建迷信的；
            （5）散布谣言、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            （6）侮辱、诽谤他人，或侵害他人合法权利的；
            （7）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
            （8）其他违反法律、法规、规章、条例以及社会道德规范所限制或禁止的内容的。
            5.9.若您在使用立优教育网上服务的过程中违反上述任何规定，则立优教育有权要求您改正或直接采取一切必要的措施(包括但不限于警告、限制或禁止使用全部或部分功能、账号封禁直至注销)以减轻不当行为而造成的影响，并公告处理结果；对于违反法律法规的行为，立优教育有权向有关部门报告，您应独自承担由此产生的一切法律责任。因此导致的您账号下数据及相关信息被删除以及相关权益丧失的，该损失由您自行承担，对此立优教育不承担任何责任。
            5.10.您必须承担一切因您个人行为而直接或间接导致的法律责任。若您的行为给立优教育造成损失的，应承担赔偿责任。
            <h3>6.权利声明</h3>
            6.1.所有立优教育网络培训平台上的及立优教育在提供网上服务的过程中使用的组成元素均属于立优教育所有，并受版权、商标、专利和其它财产所有权法律的保护。严禁任何个人或单位在未经立优教育许可的情况下对上述内容进行翻版、复制、转载、篡改等一切侵权行为。您只有在本公司授权下才能为自用目的合理使用这些内容，而不能擅自复制、再造这些内容、或创造与这些内容有关的衍生作品或产品，否则，将承担法律责任。
            6.2.立优教育对其专有内容、原创内容和其他通过授权取得的独占或者独家内容享有知识产权。未经立优教育书面许可，任何单位和个人不得私自予以转载、传播或者有任何其他侵犯立优教育知识产权的行为。否则，将承担法律责任。
            6.3.若您使用立优教育网络培训平台的任何内容，均应注明其“来源于立优教育”并署上作者姓名，且与此相关的一切法律责任由您独立承担。立优教育对所有作品享有用于其它用途的优先权，包括但不限于网站、电子杂志、平面出版等，但在使用前会通知作者，并按同行业的标准支付稿酬。您将该账号在任何时间段发表的任何形式的内容的著作财产权无偿授权给立优教育使用，同时许可立优教育有权就任何主体侵权而单独提起诉讼，并获得赔偿。本协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于您在立优教育网站发布的任何受著作权法保护的内容。
            6.4.您应保证您是在立优教育上传或发表内容的著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。若第三方提出关于著作权的异议，立优教育有权根据实际情况删除相关的内容，且有权追究您的法律责任。您的上述行为给立优教育或任何第三方造成损失的，应负责全额赔偿。
            6.5.为营造公平、健康的网络环境，在您使用立优教育网上服务的过程中，立优教育有权通过技术手段了解您的相关终端设备信息。一经发现有任何未经授权、危害立优教育网上服务正常运营的相关程序，立优教育将收集所有与此有关的信息并采取合理措施予以打击。
            6.6.本站所有内容仅代表作者自己的立场和观点，与本站无关，由作者本人承担一切法律责任。恶意转载立优教育网络培训平台上内容的，本站保留将其诉诸法律的权利。
            6.7.“立优教育”为立优教育及其关联公司的注册商标，受中国法律保护。
            对于“立优教育”等商标，任何人不得擅自使用。违反上述声明而给立优教育造成损失的，立优教育将依法追究其法律责任。
            <h3>7.免责声明</h3>
            7.1.由于互联网各个环节存在不稳定因素，立优教育无法保证其提供的网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。
            7.2.为方便用户，立优教育网络培训平台可能会提供其他国际互联网网站或资源的链接，但立优教育不保证前述网站或资源是否可以利用，亦不保证外部链接的准确性和完整性。同时，对于该等外部链接指向的不由立优教育实际控制的任何网页上的内容，立优教育不承担任何责任。若您因使用或依赖上述网站或资源受到损失或损害，立优教育及立优教育网络培训平台不承担任何责任。
            7.3.对于因不可抗力或立优教育不能控制的原因造成的网络服务中断或其它缺陷造成的损失，立优教育不承担任何责任。由于地震、台风、洪水、火灾、战争、政府禁令以及其他不能预见并且对其发生和后果不能防止或避免的不可抗力原因，或互联网上的黑客攻击事件、计算机病毒、电信管制、停电，用户操作不当等非立优教育原因，导致本服务条款不能履行或不能完全履行，立优教育不承担责任。
            7.4.立优教育有权于任何时间暂时或永久修改或终止部分或全部非付费服务，而无论其通知与否，立优教育对您和任何第三人均无需承担任何责任。
            7.5.立优教育可根据知悉或掌握的信息独立决定随时变更、终止、中止您对任何立优教育网上服务的使用，无须向您发出任何提前通知，但根据法律法规规定、本协议或具体服务规则需要提前通知的除外。立优教育无需向您承担与此相关的任何责任，且有权要求您承担相应责任。
            7.6.立优教育网上服务可能因软件漏洞、缺陷、版本更新缺陷、第三方病毒攻击、互联网连接或其他任何因素导致您的账号数据发生异常。在数据异常的原因未得到查明前，立优教育有权暂时冻结账号，且立优教育无须向您承担任何责任。
            7.7.立优教育不对您与第三方交易的行为负责，包括但不限于您通过购买、接受赠与或者其他的方式从任何第三方获得产品、服务等，并且不受理因任何第三方交易发生纠纷而带来的申诉。
            7.8.在您使用立优教育网上服务期间所存在的风险及一切后果将完全由用户本人承担。立优教育对此不承担任何责任。
            7.8.1.在使用本协议项下服务的过程中，您可能会遇到网络信息或其他用户行为带来的风险，立优教育不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给用户造成的损害负责。这些风险包括但不限于：来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；其他因网络信息或用户行为引起的风险。
            7.8.2.您经由立优教育网络培训平台与广告商进行通讯联系或商业往来或参与促销活动的行为，完全属于您与广告商之间的行为，与立优教育及立优教育网络培训平台没有任何关系。若您因上述行为受到任何损害或损失，立优教育或立优教育网络培训平台不承担任何责任。
            <h3>8.隐私政策</h3>
            8.1.个人信息的范围包括但不限于：
            (1)您在注册、报名或以其他形式使用立优教育的网上服务时，根据立优教育的要求提供的个人信息；
            (2)
            您在使用立优教育网上服务、参加立优教育活动或访问立优教育网络培训平台时，立优教育网络培训平台自动采集的技术信息，包括但不限于IP地址信息、位置信息、设备及软件信息等；
            (3)立优教育收集到的您使用立优教育网络培训平台服务的有关数据，包括但不限于购买培训课程、参加培训课程、通过立优教育网络培训平台分享的信息等；
            (4)立优教育通过其它合法途径获得的个人数据。 8.2.个人信息使用规则
            8.3.您同意并授权立优教育为履行本协议之目的收集和使用您的个人信息。立优教育可能基于以下目的使用用户的个人信息：
            (1)为提供服务的要求所必须； (2)改进产品或服务；
            (3)提供或推广立优教育及其合作伙伴的产品和服务。
            8.4.立优教育承诺对您的注册资料严格保密，不以任何形式公开、透露注册用户的真实姓名、地址、电子邮箱等信息，但以下情况除外：
            (1)事先获得用户的明确授权； (2)为提供本协议项下的服务所必须；
            (3)适用法律、法规、司法机关命令、法律程序或政府机关要求；
            (4)因立优教育自身合并、收购、资产转让或类似的交易导致用户信息转移；
            (5)与立优教育关联公司、合作方、为提供服务所必需的供应商分享。
            (6)为维护社会公众的利益。
            8.5.立优教育不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。任何用户若从事上述活动，一经发现，立优教育有权立即终止与该用户的服务协议，查封其账号。
            8.6.立优教育在对应用程序更新的过程中，可能自动调取、收集您的客户端软件版本信息等信息进行替换、修改、删除和/或补充。此种行为是软件更新的所必须的一种操作或步骤，若您不同意进行此种操作，请您不要进行更新；您的使用行为即视为您同意立优教育进行此种操作。
            8.7.立优教育可能会与第三方合作向用户提供相关的网络服务，在此情况下，若该第三方同意承担与立优教育同等的保护用户隐私的责任，则立优教育有权将用户的注册资料等提供给该第三方。
            <h3>9.管辖与法律适用</h3>
            9.1.本协议的签订地为中华人民共和国北京市海淀区。
            9.2.出现本协议引起或与本协议有关争议时，双方应首先通过友好协商解决争议；协商不成的，可以向合同签订地人民法院提起诉讼。
            9.3.本协议条款无论因何种原因部分无效或不具有执行力，其余条款仍有效并具有约束力。
            9.4.本公司不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响本公司在将来行使该权利。
            9.5.本协议根据现行中华人民共和国法律法规制定。如发生协议条款与中华人民共和国法律法规相抵触时，则这些条款将完全按法律法规的规定重新解释，但本协议的其它条款仍对立优教育和用户具有法律约束力。
            9.6.本协议解释权及修订权归长沙立优教育科技股份有限公司所有。
            长沙立优教育科技有限公司 2021年4月24日
          </span>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="dialogVisible = false"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import crypto from "crypto";
export default {
  data() {
    return {
      bannerImg: "/assets/img/logon.png", //banner
      logoImg: "/assets/img/denglu_logo.png", //logo
      dialogVisible: false,
      showDailog: false,
      mobile: "", // 用户手机号
      verification_code: "", // 图形验证码
      // nonce_str: "", // 随机串
      pwd: "",
      code: "", // 验证码
      // url: "",
      form: {
        mobile: "", // 用户手机号
        password: "", // 用户密码
        verification_code: "", // 图形验证码
        nonce_str: "", // 随机串
        pwd: "", // 用户密码
        code: "", // 短信验证码
        //   subject_id: 1,  // 科目ID
        value: "",
      },
      options: [],
      disabled: false,
      time: 60,
      btntxt: "获取短信验证码",
      captchaImg: require("../../assets/img/click.png"), //图形验证码图片
    };
  },
  methods: {
    // 注册协议弹窗
    handleClose(done) {
      this.dialogVisible = false;
    },
    showMask() {
      this.dialogVisible = true;
      this.showDailog = true;
    },
    // 点击切换图形验证码
    getImgCode() {
      var md5 = crypto.createHash("md5");
      md5.update(Math.ceil(Math.random() * 10) + "" + Date.now());
      this.form.nonce_str = md5.digest("hex");
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$message.error({duration:1500,message:"请输入正确手机号!"});
        return false;
      } else {
        this.captchaImg =
          this.url.apiUrl +
          "/api/verification_code/verification?nonce_str=" +
          this.form.nonce_str +
          "&mobile=" +
          this.form.mobile +
          "&w=" +
          130 +
          "&h=" +
          38;
      }
    },
    // 发送短信验证码
    sendCode() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$message.error({duration:1500,message:"请输入正确手机号!"});
        return false;
      } else if (this.form.verification_code == "") {
        this.$message.error({duration:1500,message:"请输入正确图形验证码!"});
        return;
      } else {
        var data = `mobile=${this.form.mobile}&event=register&verification_code=${this.form.verification_code}&nonce_str=${this.form.nonce_str}`
        // {
        //   mobile: this.form.mobile,
        //   event: "register",
        //   verification_code: this.form.verification_code,
        //   nonce_str: this.form.nonce_str,
        // };
        console.log(data)
        api.send(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
          this.$message.success({duration:1500,message:res.data.msg});
            this.time = 60;
            this.timer();
          } else {
            this.$message.error({duration:1500,message:res.data.msg});
          }
        });
      }
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "发送验证码";
        this.disabled = false;
      }
    },
    // 科目列表
    getSubject() {
      api.subject().then((res) => {
        this.options = res.data.data;
      });
    },
    // 选择科目
    changeMajor(id) {

    },
    // 立即注册
    registerNow() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$message.error("请输入正确手机号!");
        return false;
      } else if (this.form.verification_code == "") {
        this.$message.error("请输入正确图形验证码!");
        return;
      } else if (this.form.code == "") {
        this.$message.error("请输入正确短信验证码!");
        return;
      } else if (this.form.pwd == "" && this.form.pwd.length < 6) {
        this.$message.error("请输入正确的密码,且密码长度不低于6位!");
        return;
      } else if (this.form.value == "") {
        this.$message.error("请选择科目!");
        return;
      }
      var data = {
        mobile: this.form.mobile,
        password: this.form.pwd,
        subject_id: this.form.value,
        code: this.form.code,
      };
      api.register(this.qs.stringify(data)).then((res) => {
        if (res.data.code == 1) {
          this.$message.success({duration:1500,message:"注册成功"});
          // location.href = "/pc/index.html";
          this.$router.push({name:'Index'})
        } else {
          this.$message.error({duration:1500,message:res.data.msg});
        }

      });
    },
  },
  created() {
    this.getSubject();
  },
};
</script>

<style lang="scss" scoped>
// @import url("./index.css");

.password .el-input__inner {
  background: url(/img/see.png) no-repeat 10px center;
  padding: 0px 50px;
  width: 534px;
  height: 66px;
  font-size: 23px;
  color: #dadada;
}

.banner {
  width: 100%;
  height: 490px;
  object-fit: cover;
  /* position: relative; */
}

#app .down {
  position: relative;
  width: 100%;
  height: 590px;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) inset; */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04) inset;
  margin-bottom: 30px;
}

#app .down .login_card {
  position: absolute;
  top: -302px;
  left: 0;
  right: 0;
  width: 1100px;
  height: 529px;
  /* background: pink; */
  margin: auto;
  z-index: 1;
}

#app .down .record_number {
  // width: 511px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
  margin: auto;
}

.login_card .login_box {
  width: 1100px;
  height: 572px;
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(173, 192, 238, 0.6);
  border-radius: 20px;
  margin-top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_card .login_box .login {
  width: 919px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_card .login_box .login img {
  width: 349px;
  height: 78px;
}

.login_box .login #login_line {
  width: 2px;
  height: 260px;
  background: #e7e7e7;
  /* background: red; */
}

.login_box .login .login_right {
  width: 388px;
  /* background: pink; */
}

.login_box .login .login_right div:nth-child(1) {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #666666;
  text-align: center;
}

.login .login_right .register_ipt {
  position: relative;
  margin-bottom: 16px;
}

/* .login .login_right .register_ipt input {
    width: 388px!important;
    height: 48px;
} */

.login .login_right .register_ipt input {
  width: 353px;
  height: 48px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding-left: 40px;
  outline: none;
  font-size: 18px;
  // font-family: PingFang SC;
  // font-weight: 400;
  color: #333;
}
.login .login_right .register_ipt input::placeholder {
  // width: 116px;
  // height: 16px;
  font-size: 16px;
  // font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  // line-height: 48px;
}
.login .login_right .register_ipt img {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 22px;
  height: 19px;
  z-index: 9;
  margin-right: 6px;
}

.login .login_right .get_code {
  display: flex;
  justify-content: space-between;
}

.login .login_right .get_code input {
  width: 205px;
}

.login .login_right .get_code .send_code {
  /* display: inline-block; */
  width: 135px;
  height: 48px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #3e7eff;
  line-height: 48px;
  text-align: center;
  background: #fff;
}

.treaty_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.treaty_box li {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 300;
  color: #333333;
}

.treaty_box li:nth-child(1) span {
  font-size: 14px;
  font-family: PingFang SC;
  color: #3e7eff;
}

.treaty_box li:nth-child(2) a {
  font-size: 14px;
  font-family: PingFang SC;
  color: #3e7eff;
  margin-top: -16px;
}

.register_ipt .img_code_box {
  position: relative;
}

.register_ipt .img_code_box input {
  border: none;
}

.register_ipt .img_code_box .code_img {
  position: absolute;
  top: -3px;
  left: 290px !important;
}

/* 立即注册按钮 */

.register_btn {
  width: 388px;
  height: 48px;
  background: linear-gradient(259deg, #3e7eff 0%, #2765f3 100%);
  box-shadow: 0px 9px 22px 0px rgba(81, 133, 240, 0.8);
  border-radius: 24px;
  outline: none;
  border: none;
  margin-top: 34px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 48px;
}

#mask_title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

#mask {
  white-space: pre-line;
  line-height: 22px;
}

/deep/ .el-select .el-input__inner {
  // height: 50px!important;
  color: #333;
  font-size: 16px;
  font-family: PingFang SC;
  min-height: 50px;
  padding-left: 37px;
}
/deep/ .el-input__inner::placeholder {
  // width: 148px;
  // height: 15px;
  font-size: 16px;
  font-family: PingFang SC;
  // font-weight: 400;
  color: #999999;
  line-height: 48px;
}
input::-moz-input-placeholder {
  color: #999;
}

#redom {
  top: 10px;
  left: -36px;
}
</style>
